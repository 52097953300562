$mdc-theme-primary: darkseagreen;

@import "@material/react-drawer/index.scss";
@import "@material/react-top-app-bar/index.scss";
@import "@material/react-material-icon/index.scss";
@import "@material/react-chips/index.scss";
@import "@material/react-layout-grid/index.scss";
@import "@material/react-typography/index.scss";
@import "@material/react-checkbox/index.scss";
@import "@material/react-select/index.scss";

body {
  color: rgba(0,0,0,.84);
  font-size: 1.2em;
  letter-spacing: .003em;
  line-height: 1.5em;
}

@media (min-width: 840px) {
  .desktop-drawer-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
  }
  .desktop-drawer-app-content {
    flex: auto;
    overflow: auto;
  }
  .modal-drawer {
    display: none !important;
  }
  .mobile-appbar {
    display: none !important;
  }
}

@media (max-width: 839px) {
  .permanent-drawer {
    display: none !important;
  }
  .desktop-appbar {
    display: none !important;
  }
}

.countries-filter {
  @include mdc-chip-fill-color-accessible(darkseagreen);
}
.species-filter {
  @include mdc-chip-fill-color-accessible(palevioletred);
}
.organismGroups-filter {
  @include mdc-chip-fill-color-accessible(yellow);
}
.phenotypes-filter {
  @include mdc-chip-fill-color-accessible(brown);
}
.specialities-filter {
  @include mdc-chip-fill-color-accessible(gray);
}
.sources-filter {
  @include mdc-chip-fill-color-accessible(purple);
}
.mdc-chip__text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-container {
  padding: 20px;
  max-width: 720px;
  box-sizing: border-box;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}

.VictoryContainer svg {
  pointer-events: none !important;
}